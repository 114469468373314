html, body {
    background-color: #ffffff;
}

a {
    color: #FEC63D;
    text-decoration: none;
}

body {
    padding-top: 60px;
}

footer {
    background: #000000;
    padding: 24px;
}

footer, footer * {
    color: #ffffff;
}

h1, h2, h3, h4, h5, h6 {
    color: #FEC63D;
    margin-bottom: 1.6rem;
    margin-top: 10px;
}

h1 {
    font-size: 2.2rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.8rem;
}

h4 {
    font-size: 1.6rem;
}

h5 {
    font-size: 1.4rem;
}

h6 {
    font-size: 1.2rem;
}

.badge {
    background-color: #FEC63D;
    border-radius: 4px;
    padding: 8px;
}

.bg-dark {
    background-color: #000000 !important;
}

.bg-dark, .bg-dark * {
    color: #ffffff;
}

.bi {
    color: #000000 !important;
}

.btn {
    color: #000000 !important;
    background-color: #ffffff !important;
    border: solid 1px #444444;
    margin-bottom: 4px;
}

.btn-primary {
    background-color: #FEC63D !important;
}

.btn-show-filters {
    margin-top: 16px;
}

.btn:hover {
    background-color: #FEC63D !important;
}

.cctv-incident-open-row, .cctv-incident-open-row td {
    background-color: #FFFACD !important;
}

.cctv-incident-open-row > td {
    color: #FF0000 !important;
}

.cctv-incident-open-row > td > .btn-group > .btn-primary {
    color: #FFFFFF !important;
}

.form-label {
    font-weight: bold;
}

.form-label, .form-prompt {
    padding-bottom: 8px;
}

.home-block-left {
    padding: 24px;
    padding-top: 48px;
}

.home-buttons {
    margin-top: 24px;
}

.home-heading {
    margin-bottom: 24px;
}

.home-icons {
    margin-top: 24px;
    text-align: center;
}

.home-icons .bi {
    background-color: #FEC63D;
    border-radius: 8px;
    font-size: 2.5rem;
    padding: 12px;
}

.home-icons h4 {
    margin-top: 12px;
}

.home-page-img {
    background-image: url('../images/home-right.jpg');
    background-repeat: no-repeat;
    background-position: bottom right;
    min-height: 500px;
}

.home-training {
    margin-bottom: 24px;
}

.nav-item {
    border: none;
    margin: 4px;
}

.navbar-nav .nav-link, .nav-link {
    color: #000000 !important;
}

.navbar-toggler {
    margin-right: 8px;
}

.odd-row, .odd-row td {
    background-color: #EEEEEE;
}

.page-header {
    margin-top: 2px;
}

.pagination a {
    margin: 8px;
}

.precinct-name {
    color: #FEC63D;
}

.progressbar-around {
    background-color: rgba(0, 0, 0, 0.8);
    display: none;
    height: 100%;
    left: 0;
    padding-top: 120px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.progressbar-around h4 {
    color: #ffffff;
}

.progressbar-around #progressbar {
    background-color: #ffffff;
    color: #000000;
    font-weight: bold;
    height: 50px;
    padding: 12px;
    text-align: center;
    width: 100%;
}

.required {
    color: #b33336;
}

.space-above {
    margin-top: 12px;
}

.text-info {
    color: #dc3232 !important;
    padding-left: 12px;
}

.text-large {
    font-size: 1.5rem;
}

.text-left {
    text-align: left;
}

.text-overflow-nowrap {
    white-space: nowrap;
}

.text-right {
    text-align: right;
}

.text-small {
    font-size: 10px;
}
